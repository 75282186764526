import packageJson from '../package.json';

export const DESIGN_SYSTEM_VERSION = packageJson.dependencies[
  '@economist/design-system'
].replace(/\^/, '');
export const DESIGN_SYSTEM_STYLES = `https://design-system.economist.com/dist/${DESIGN_SYSTEM_VERSION}/umd/common.css`;
export const DESIGN_SYSTEM_REPO = `https://github.com/EconomistDigitalSolutions/design-system`;

export const routes = {
  HOME: '/',
  COMPONENTS: '/components',
  COMPONENTS_OVERVIEW: '/components/overview',
  FOUNDATIONS: '/foundations',
  FOUNDATIONS_OVERVIEW: '/foundations/overview',
  PATTERNS: '/patterns',
  PATTERNS_OVERVIEW: '/patterns/overview',
  PLAYGROUND: '/playground',
  DEVELOPER_RESOURCES: '/developer-resources',
  BRAND_MARKETING: '/brand-and-marketing',
  FE_GUIDELINES: '/front-end-guidelines',
  CONTRIBUTING: '/contributing',
  DESIGN_TOKENS: '/design-tokens',
};

export const keyCodes = {
  TAB: 9,
  ESC: 27,
  SPACE: 32,
  ENTER: 13,
};

export const EMAIL_FEEDBACK = 'design-system@economist.com';
export const BANNER_TEXT =
  'This is a new website—we are regularly adding more content and improving the experience.';
export const BANNER_BADGE_TEXT = 'Beta';

// Hide the Feedback component from these pages
export const HIDE_FEEDBACK_URLS = [
  routes.HOME,
  routes.COMPONENTS,
  routes.COMPONENTS_OVERVIEW,
  routes.FOUNDATIONS,
  routes.FOUNDATIONS_OVERVIEW,
  routes.PATTERNS,
  routes.PATTERNS_OVERVIEW,
  routes.BRAND_MARKETING,
  routes.DEVELOPER_RESOURCES,
];
